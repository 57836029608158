import { BillingAddress, ShippingAddress } from '../../models/address.model'
import { BulkUpdateMethod } from '../../models/bulk-update.model'
import { Entity } from '../../models/entity.model'
import {
  QueryStringParams,
  QuerytStringSortOrder,
} from '../../models/query-string.model'
import { Meta, StatusHistory } from '../../models/util.model'
import { InvoiceType } from '../invoices/invoice.model'
import {
  Product,
  ProductBatchSignature,
  ProductLocation,
} from '../products/product.model'
import { Promotion } from '../promotions/promotion.model'
import { PickingList } from '../picking-lists/picking-list.model'
import { User } from '../users/user.model'
import { Tag } from '../tags/tag.model'
import { Currency } from '../currencies'
import { Payment } from '../payments'
import { Warehouse } from '../warehouses'
import { Country } from '../countries'
import { Carrier } from '../carriers'
import { Channel } from '../channels'
import { Attachment } from '../files'
import { Reason } from '@evologi/shared/data-access-api'

export type OrderType = 'CUSTOMER' | 'TRANSFER'

export enum OrderStatus {
  drafted = 'DRAFTED',
  pending = 'PENDING',
  confirmed = 'CONFIRMED',
  processing = 'PROCESSING',
  processed = 'PROCESSED',
  packed = 'PACKED',
  shipped = 'SHIPPED',
  delivered = 'DELIVERED',
  parked = 'PARKED',
  handed = 'HANDED',
  canceled = 'CANCELED',
  dropshipped = 'DROPSHIPPED',
}

export enum OrderTransport {
  sender = 'SENDER',
  recipient = 'RECIPIENT',
  carrier = 'CARRIER',
}

export interface OrderDashboard {
  ordersCount: number
  itemsSold: number
  averageItemsPerOrder: number
  averageTotalOrderAmount: number
  averageRowsPerOrder: number
  ordersPerChannels: Record<string, number>
  ordersPerStatus: Record<string, number>
}

export interface OrderExportAmazonShipData {
  channelId: string | undefined
  startDate: Date
  endDate: Date
  email: string
}

export interface OrderCounters {
  DRAFTED: number
  PENDING: number
  CONFIRMED: number
  PROCESSING: number
  PROCESSED: number
  PACKED: number
  SHIPPED: number
  IMPORTANT_NOTE: number
}

export interface Order extends Entity {
  // Status info
  type: OrderType
  status: OrderStatus
  statusHistory: StatusHistory[]
  splitted?: boolean
  returned?: boolean
  duplicated?: boolean

  // Ext keys
  warehouseId?: string
  assignedWarehouseId?: string
  userId?: string
  bordereauId?: string
  pickingListId?: string
  invoiceId?: string
  groupId?: string

  // Counters
  productsCount?: number
  rowsCount?: number

  // Additional info
  tags?: string[]

  // Carrier & shipment data
  carrierId?: string
  shipmentOptions?: OrderShipmentOptions

  // Header & footer document data
  header: OrderHeader

  // Rows
  rows: OrderRow[]

  // Packing data
  packingDate?: Date
  packagesCount: number
  packages?: OrderPackage[]
  additionalPackages?: OrderAdditionalPackage[]

  /**
   * Total products order volume(m³) and weight(kg)
   */
  estimatedVolume?: number
  estimatedWeight?: number

  attachments?: Attachment[]

  shipments?: OrderShipment[]

  /**
   * Order properties that can be edited from frontend.
   */
  meta?: Meta
  /**
   * Order properties that cannot be edited from frontend.
   */
  _meta?: Meta
}

export interface OrderShipment {
  _id?: string
  status?: string
  trackingNumbers?: string[]
}

export interface OrderShipmentOptions {
  service?: string | number
  insurance?: boolean
  callBeforeDelivery?: boolean
  deliveryDate?: Date
}

export interface OrderHeader {
  orderNumber?: number
  date?: string
  currency: string
  rifOrder?: string
  rifDate?: string
  shippingDate?: string

  invoiceType?: InvoiceType
  requestInvoice?: boolean
  paid?: boolean

  cashOnDelivery?: OrderCashOnDelivery
  gift?: OrderGift

  discount: OrderDiscount

  shippingCost: number // Costo spedizione IVA esclusa
  shippingCostWithTaxes: number // Costo spedizione IVA inclusa
  shippingTaxRate: number // Percentuale IVA spese di spedizione
  shippingTaxes: number // Importo IVA delle spese di spedizione
  subTotal: number // Totale corpo iva esclusa
  subTotalWithTaxes: number // Totale corpo iva inclusa
  bodyTaxes: number // Totale tasse corpo
  totalOrderAmount: number // Totale ordine IVA inclusa comprese spese di spedizione e costo di contrassegno.

  channel: string
  transport?: OrderTransport
  paymentType?: string
  payments?: OrderPayment[]
  reasonId?: string

  billingAddress?: BillingAddress
  shippingAddress?: ShippingAddress

  notes?: string
  carrierNotes?: string
  automaticCarrier?: boolean
  packingNotes?: string
  importantNote?: boolean
  errors?: string[]
  promotions?: Promotion[]
}

export interface OrderPayment {
  date: string
  code?: string
  amount?: number
  consignee?: string
}

export interface OrderCashOnDelivery {
  fee: number // Costo contrassegno IVA esclusa
  feeWithTaxes: number // Costo contrassegno IVA inclusa
  taxRate: number // Percentuale IVA costo contrassegno
  taxes: number // Importo IVA costo contrassegno
  amount: number // Importo che deve incassare il corriere
}

export interface OrderDiscount {
  discountPercentage?: number
  discountWithTaxes: number
  discountTaxRate: number
  discountWithoutTaxes: number
}

export interface OrderRow {
  _id?: string

  // Quantities
  orderedQty: number
  shippedQty: number
  splitQty?: number

  // Prices
  unitPrice: number // Prezzo unitario articolo IVA esclusa
  unitPriceWithTaxes: number // Prezzo unitario articolo IVA inclusa
  productTaxRate: number // Percentuale IVA
  discount: number // Sconto di riga IVA esclusa
  discountWithTaxes: number // Sconto di riga IVA inclusa
  discountPercentage?: number // Sconto percentuale riga
  totalTaxes: number // Totale IVA di riga calcolato sulla base di dei prezzi dei prodotti * qta ord - sconto di riga
  totalAmount: number // Totale di riga IVA esclusa scontato
  totalAmountWithTaxes: number // Totale di riga IVA inclusa scontato

  // Product info
  product: OrderRowProduct

  /**
   * Order properties that can be edited from frontend.
   */
  meta?: Meta
  /**
   * Order properties that cannot be edited from frontend.
   */
  _meta?: Meta
}

export type OrderRowProduct =
  | OrderRowProductSimple
  | OrderRowProductKit
  | OrderRowProductService

export interface OrderRowProductGeneric {
  _id: string
  SKU: string
  name?: string
}

export interface PickingLocation {
  _id: string
  quantity: number
  batches?: PickingLocationBatch[]
}

export interface PickingLocationBatch extends ProductBatchSignature {
  quantity: number
}

export interface OrderRowProductService extends OrderRowProductGeneric {
  productType: 'SERVICE'
}

export interface OrderRowProductSimple extends OrderRowProductGeneric {
  productType: 'SIMPLE'
}

export interface OrderRowProductKit extends OrderRowProductGeneric {
  productType: 'KIT'
  simpleProducts: OrderRowProductComponent[]
}

export interface OrderRowProductComponent extends OrderRowProductGeneric {
  /**
   * Quantità prodotto simple che compone il kit
   */
  quantity: number
  /**
   * Total shipped quantity for this KIT component.
   */
  shippedQty?: number
}

export type OrderPackageType = 'package' | 'additionalPackage'

export interface OrderPackage {
  _id?: string
  packageId?: string
  packageNumber?: number
  weight: number
  packWeight?: number
  volume: number
  height: number
  length: number
  width: number
  handDate?: Date
  parkingDate?: Date
}

export interface OrderAdditionalPackage {
  /**
   * Package unique identifier.
   */
  _id?: string
  /**
   * Product package identifier.
   */
  packageId?: string
  /**
   * Number of used packages of this kind.
   */
  quantity: number
  /**
   * Centimeters.
   */
  height: number
  /**
   * Centimeters.
   */
  length: number
  /**
   * Centimeters.
   */
  width: number
  /**
   * Cubic meters.
   */
  volume: number
  /**
   * Kilograms.
   */
  weight: number
}

export interface OrderGift {
  isEnabled: boolean
  message?: string
  taxRate: number
  taxes?: number
  cost: number
  costWithTaxes: number
}

export interface OrderExportData {
  type: 'all' | 'giftCard' | 'coupon'
  startDate?: Date
  endDate?: Date
  email?: string
}

export enum Coverage {
  available = 'AVAILABLE',
  unavailable = 'UNAVAILABLE',
  partial = 'PARTIAL',
  assigned = 'PL-ASSIGNED',
}
export interface OrderCoverage {
  _id: string
  coverage: Coverage
  rows: OrderRowCoverage[]
}

export interface OrderRowCoverage {
  _id: string
  coverage: Coverage
}

export type OrderChangeStatusAction =
  | 'cancel'
  | 'cancelShipment'
  | 'confirm'
  | 'deliver'
  | 'dropship'
  | 'hand'
  | 'manage'
  | 'pack'
  | 'park'
  | 'pend'
  | 'process'
  | 'ship'

export type OrderPermission =
  | 'cancelOrder'
  | 'cancelShipment'
  | 'checkAddress'
  | 'checkBordereau'
  | 'checkCashOnDelivery'
  | 'checkPackFinished'
  | 'checkPickError'
  | 'checkPickingList'
  | 'checkRows'
  | 'copyableRef'
  | 'deleteOrder'
  | 'dropShip'
  | 'editAlert'
  | 'editCashOnDelivery'
  | 'editHeader'
  | 'editPacking'
  | 'editPayment'
  | 'editProducts'
  | 'editPromotion'
  | 'editRef'
  | 'editShipping'
  | 'packOrder'
  | 'pickUpProducts'
  | 'pickOrder'
  | 'printLabel'
  | 'printSummary'
  | 'resetPicking'
  | 'saveOrder'
  | 'showPackageInfo'
  | 'showPickedQuantities'
  | 'showProductQuantities'
  | 'showShipmentInfo'
  | 'showShippedQuantities'
  | 'splitOrder'
  | 'toggleAmountsEditable'

export interface Shipment {
  _id: string
  status: string
  tenantId: string
  warehouseId: string
  orderId: string
  carrierId: string
  externalCarrierCode?: number
  externalCarrierService?: number
  externalId?: string | number
  progressiveNumbers: number[]
  cashOnDeliveryAmount?: number
  shippingAddress: any
  trackingNumbers?: string[]
  packages?: {
    weight: number
    volume: number
    height: number
    length: number
    width: number
  }[]
  labels?: ShipmentLabel[]
  response: unknown
}

export interface ShipmentLabel {
  _id?: string
  filePath: string
  type?: 'PDF'
  fileType?: 'pdf' | 'zpl'
  /**
   * Label height in millimeters
   */
  height?: number
  /**
   * Label width in millimeters
   */
  width?: number
}

export interface OrderShipmentData {
  shipment: Shipment
  tracking: ShipmentTracking
}

export interface OrderPackagesData {
  packages?: OrderPackage[]
  additionalPackages?: OrderAdditionalPackage[]
  products?: Product[]
}

export interface ShipmentTracking {
  number: string
  link?: string
}
export interface OrderNotification {
  code: OrderNotificationCode
  type: OrderNotificationType
  data?: any
}

export type OrderNotificationCode =
  | 'ORDER_INCOMPLETE'
  | 'ORDER_SAVED'
  | 'SKU_NOT_VALID'
  | 'ORDER_PRINTED'
  | 'PACKAGE_NOT_FOUND'
  | 'PACKAGE_NOT_AVAILABLE'
  | 'PACKAGE_ADDED'
  | 'PACKAGE_DISABLED'

export type OrderNotificationType = 'success' | 'warning' | 'error'

export type OrderSortField =
  | 'createdAt'
  | 'estimatedVolume'
  | 'estimatedWeight'
  | 'groupId'
  | 'header.date'
  | 'header.orderNumber'
  | 'header.rifDate'
  | 'header.rifOrder'
  | 'header.shippingDate'
  | 'packagesCount'
  | 'packingDate'
  | 'productsCount'
  | 'rowsCount'

export interface OrderSearchParams extends QueryStringParams<OrderSortField> {
  '_id:ne'?: string | string[]
  assignedWarehouseId?: string | string[]
  bordereauId?: string | string[]
  'bordereauId:ex'?: boolean
  carrierId?: string | string[]
  type?: OrderType
  estimatedVolume?: number
  'estimatedVolume:ne'?: number
  'estimatedVolume:lt'?: number
  'estimatedVolume:le'?: number
  'estimatedVolume:gt'?: number
  'estimatedVolume:ge'?: number
  estimatedWeight?: number
  'estimatedWeight:ne'?: number
  'estimatedWeight:lt'?: number
  'estimatedWeight:le'?: number
  'estimatedWeight:gt'?: number
  'estimatedWeight:ge'?: number
  groupId?: string | string[]
  'groupId:ne'?: string
  'header.billingAddress.countryCode'?: string | string[]
  'header.billingAddress.countryCode:ne'?: string | string[]
  'header.channel'?: string | string[]
  'header.currency'?: string | string[]
  'header.date'?: string
  'header.date:ne'?: string
  'header.date:lt'?: string
  'header.date:le'?: string
  'header.date:gt'?: string
  'header.date:ge'?: string
  'header.externalRifOrder'?: string
  'header.externalRifOrder:ne'?: string
  'header.gift.isEnabled'?: boolean
  'header.importantNote'?: boolean
  'header.invoiceType'?: InvoiceType | InvoiceType[]
  'header.orderNumber'?: number
  'header.orderNumber:ne'?: number
  'header.orderNumber:lt'?: number
  'header.orderNumber:le'?: number
  'header.orderNumber:gt'?: number
  'header.orderNumber:ge'?: number
  'header.packingNotes'?: string
  'header.packingNotes:ne'?: string
  'header.packingNotes:ct'?: string
  'header.paid'?: boolean
  'header.paymentType'?: string | string[]
  'header.requestInvoice'?: boolean
  'header.rifDate'?: string
  'header.rifDate:ne'?: string
  'header.rifDate:lt'?: string
  'header.rifDate:le'?: string
  'header.rifDate:gt'?: string
  'header.rifDate:ge'?: string
  'header.rifOrder'?: string | string[]
  'header.rifOrder:ne'?: string
  'header.shippingAddress._id'?: string | string[]
  'header.shippingAddress.countryCode'?: string | string[]
  'header.shippingAddress.countryCode:ne'?: string | string[]
  'header.shippingDate'?: string
  'header.shippingDate:ne'?: string
  'header.shippingDate:lt'?: string
  'header.shippingDate:le'?: string
  'header.shippingDate:gt'?: string
  'header.shippingDate:ge'?: string
  'header.transport'?: OrderTransport | OrderTransport[]
  packagesCount?: number
  'packagesCount:ne'?: number
  'packagesCount:lt'?: number
  'packagesCount:le'?: number
  'packagesCount:gt'?: number
  'packagesCount:ge'?: number
  packingDate?: string
  'packingDate:ne'?: string
  'packingDate:lt'?: string
  'packingDate:le'?: string
  'packingDate:gt'?: string
  'packingDate:ge'?: string
  pickingListId?: string | string[]
  'pickingListId:ex'?: boolean
  productsCount?: number
  'productsCount:ne'?: number
  'productsCount:lt'?: number
  'productsCount:le'?: number
  'productsCount:gt'?: number
  'productsCount:ge'?: number
  referredWarehouseId?: string
  rowsCount?: number
  'rowsCount:ne'?: number
  'rowsCount:lt'?: number
  'rowsCount:le'?: number
  'rowsCount:gt'?: number
  'rowsCount:ge'?: number
  'rows.product._id'?: string | string[]
  'rows.product.SKU'?: string | string[]
  'rows.product.simpleProducts._id'?: string | string[]
  'rows.product.simpleProducts.SKU'?: string | string[]
  'rows.product.tags'?: string | string[]
  splitted?: boolean
  returned?: boolean
  duplicated?: boolean
  status?: OrderStatus | OrderStatus[]
  tags?: string | string[]
  warehouseId?: string | string[]
  trackingNumber?: string
  q?: string
  isPickable?: boolean
}

export type OrderPackagesActionType =
  | 'save'
  | 'process'
  | 'process&print'
  | 'process&download'
  | 'pack&print'
  | 'pack&download'
  | 'print'

export interface OrderPackagesAction {
  type: OrderPackagesActionType
  class?: string
  label: string
}

export interface OrderPickingMission {
  qtyToPick: number
  qtyPicked: number
  productId: string
  onHandQty: number
  availableQty: number
  pickingLocations: ProductLocation[]
}

export enum OrderFieldGroup {
  carrier = 'CARRIER',
}

export enum OrderBulkUpdateField {
  carrier = 'CARRIER',
}

export interface OrderBulkUpdate {
  filter: OrderSearchParams
  actions: OrderBulkUpdateAction[]
}

export interface OrderBulkUpdateResponse {
  data: {
    _id: string
    status: 'SUCCESS' | 'FAILED'
    error?: {
      code: string
      message: string
      details?: string
    }
  }[]
  totalCount: number
}

export interface OrderBulkUpdateAction {
  subject: OrderBulkUpdateField
  mode: BulkUpdateMethod
  value?: OrderBulkUpdateActionValue
}

export interface OrderBulkUpdateActionValue {
  carrierId: string
  shipmentOptions: OrderShipmentOptions
}

export interface OrdersListingSearchData {
  params: OrderSearchParams
  fields: OrderField[]
}

export interface OrderDetail {
  order?: Order
  extData?: OrderDetailData
}

export interface OrderDetailData {
  carrier?: Carrier
  warehouse?: Warehouse
}

export interface OrderDetailKeys {
  warehouseId?: string
  bordereauId?: string
  paymentId?: string
  channelId?: string
  carrierId?: string
  countryCodes?: string[]
  userIds?: string[]
  pickingListId?: string
  tagValues?: string[]
}

export interface OrdersListingPage {
  data?: Order[]
  extData?: OrdersListingData
  totalCount?: number
}

export interface OrdersListingKeys {
  warehouseIds?: string[]
  currencies?: string[]
  paymentIds?: string[]
  countryCodes?: string[]
  channelIds?: string[]
  carrierIds?: string[]
  userIds?: string[]
  pickingListIds?: string[]
  tagValues?: string[]
  reasonValues?: string[]
}

export interface OrdersListingData {
  warehouses?: Warehouse[]
  currencies?: Currency[]
  payments?: Payment[]
  countries?: Country[]
  channels?: Channel[]
  carriers?: Carrier[]
  users?: User[]
  pickingLists?: PickingList[]
  tags?: Tag[]
  products?: Product[]
  reasons?: Reason[]
}

export type OrderViewType =
  | 'ORDERS_LISTING_MAIN'
  | 'ORDERS_SELECTION_PICKING_LIST'

export type OrderField =
  | '_id'
  | 'assignedWarehouseId'
  | 'bordereauId'
  | 'carrierId'
  | 'estimatedVolume'
  | 'estimatedWeight'
  | 'groupId'
  | 'header.billingAddress.countryCode'
  | 'header.channel'
  | 'header.currency'
  | 'header.date'
  | 'header.externalRifOrder'
  | 'header.gift.isEnabled'
  | 'header.importantNote'
  | 'header.invoiceType'
  | 'header.orderNumber'
  | 'header.packingNotes'
  | 'header.paid'
  | 'header.paymentType'
  | 'header.requestInvoice'
  | 'header.rifDate'
  | 'header.rifOrder'
  | 'header.shippingAddress.name'
  | 'header.shippingAddress.address1'
  | 'header.shippingAddress.address2'
  | 'header.shippingAddress.city'
  | 'header.shippingAddress.province'
  | 'header.shippingAddress.postalCode'
  | 'header.shippingAddress.countryCode'
  | 'header.shippingAddress.phone'
  | 'header.shippingAddress.email'
  | 'header.billingAddress.name'
  | 'header.billingAddress.address1'
  | 'header.billingAddress.address2'
  | 'header.billingAddress.city'
  | 'header.billingAddress.province'
  | 'header.billingAddress.postalCode'
  | 'header.billingAddress.phone'
  | 'header.billingAddress.email'
  | 'header.shippingDate'
  | 'header.transport'
  | 'header.cashOnDelivery.amount'
  | 'header.shippingCost'
  | 'header.shippingCostWithTaxes'
  | 'header.shippingTaxes'
  | 'header.subTotal'
  | 'header.subTotalWithTaxes'
  | 'header.bodyTaxes'
  | 'header.totalOrderAmount'
  | 'header.reasonId'
  | 'totalVolume'
  | 'totalWeight'
  | 'packagesCount'
  | 'packingDate'
  | 'pickingListId'
  | 'productsCount'
  | 'rowsCount'
  | 'rows.product.tags'
  | 'splitted'
  | 'returned'
  | 'duplicated'
  | 'status'
  | 'tags'
  | 'warehouseId'
  | 'isPickable'
  | 'processedBy'
  | 'packedBy'
  | 'cee'
  | 'attachments'

export type OrderSelectionCounter = 'orders' | 'products' | 'rows' | 'size'

export interface OrderSelectionParams {
  search?: boolean
  selectionMode?: 'multiple' | 'single'
  warehouseId?: string
  searchParams?: OrderSearchParams
  columns?: OrderField[]
  sort?: OrderSortField
  order?: QuerytStringSortOrder
  counters?: OrderSelectionCounter[]
  inventoryCoverage?: boolean
  productTags?: boolean
  removeShipmentsNotRequested?: boolean
}
